import { environment } from './environment';
import { Environments } from './types';

export const baseQualityEnv: Environments = {
	production: false,
	ENV_NAME: 'quality',
	athMovilEnv: 'sandbox',
	apiLink: 'https://helios-api-onboarding.stg.apps-connectassistance.com',
	auto_dispatch_url: 'https://auto-dispatch.stg.apps-connectassistance.com',
	commonApiLink: 'https://common-api-stg.apps-connectassistance.com/api',
	customersApiUrl: 'https://api-customersetl-staging-pg.herokuapp.com',
	customersApiToken:
		'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiaGVsaW9zIn0.3-cdxNEY823SzghSYsSR3e7xV1MvBg_ows0QwB3iKs0',
	inspectionLink: 'https://quality.connectasistencia.com/formulario/',
	managementClientLink: 'https://helios-management-qa.herokuapp.com/#',
	salesApiLink: 'https://helios-sales-api-stg.apps-connectassistance.com',
	salesClientLink: 'https://helios-sales-stg.connectasistencia.com/#/',
	stripeKey:
		'pk_test_5190uUtHDk9bOOMjA7dns0vu7sAXkleiJBKscW9qOt7TdSlwMepnZsk3CtJHva6MKs4wxraMboSucd9yzBoh0aaP9003SXU0vHf',
	stripeKeyPR:
		'pk_test_5190uUtHDk9bOOMjA7dns0vu7sAXkleiJBKscW9qOt7TdSlwMepnZsk3CtJHva6MKs4wxraMboSucd9yzBoh0aaP9003SXU0vHf',
	stripeKeyPRHome:
		'pk_test_51EfZvUBG3rK0Zer3Q5pTCblIbazepqzoNczFU126bdmdpjGc7WE8ia8d50QrwC2H0vKMGUCpPdYG83xgdSv7Sohz00lr5LxlfN',
	stripeKeyPA:
		'pk_test_51CwGaHA0iubLCAyqNCojfDXNctsEls26kkoX6dy1fJnP4T3eH1Dz8TwBS0jzoUTGkykOdptc8gnLtKr6TlKVJ3mM00o1fXHvVs',
	stripeKeyCR:
		'pk_test_51Gv9rELGDymA3BfKBjBynowDjKtzIPWq4utKtNnMEkGdJrvkHnckLTIzZFvIbCoVTyvyOIrDSw5bbcEcFcJz7GfP0000yRpvK3',
	stripeKeyMX:
		'pk_test_51CwGaHA0iubLCAyqNCojfDXNctsEls26kkoX6dy1fJnP4T3eH1Dz8TwBS0jzoUTGkykOdptc8gnLtKr6TlKVJ3mM00o1fXHvVs',
	connectServicesUrl: 'https://connectservicesapp-quality.azurewebsites.net',
	eosUrl: 'https://staging--eos-web.netlify.app',
	proMigrationServiceUrl: 'https://cf8dz9xzi1.execute-api.us-east-1.amazonaws.com/dev',
	proMigrationServiceApiKey: 'qNPM5xsr9t3xVM3LhIwlua1PCwKsqLr99jkf8Tn8',
	s3BucketPR: 'devinspecciones',
	s3BucketCR: 'devinspecciones',
	s3BucketPAN: 'devinspecciones',
	s3BucketRegion1: 'us-east-1',
	s3BucketRegion2: 'us-east-1',
	ROAD_PUBLIC_TOKEN: 'sandboxtoken01875617264',
	HOME_PUBLIC_TOKEN: 'sandboxtoken01875617264',
	S3_MANAGER_SERVICE_URL: 'https://h9cw6rxejl.execute-api.us-east-1.amazonaws.com/dev',
	S3_MANAGER_SERVICE_API_KEY: 'hi6wAdl5KG2LAfJ8H9rnf3Aslsx9Jtdg3nHDZuvH',
	ETL_CLIENTS_FILES_BUCKET_NAME: 'devheliosservices',
	ETL_CLIENTS_FILES_BUCKET_REGION: 'us-west-2',
	PROFILE_IMAGES_BUCKET_NAME: 'inspecciones',
	PROFILE_IMAGES_BUCKET_REGION: 'us-east-1',
	ACCOUNT_BRANDING_BUCKET_NAME: 'connect-assistant-public-assets-stg',
	ACCOUNT_BRANDING_BUCKET_REGION: 'us-east-1',
	STORAGE_KEY: 'FB5glk0dsWjtLfFYo02H7$2FWlQ&CTrXB75f2NY44*hETEwc%2wZTsZ^d*Ho$q48FP5!XQb&G3AR8p5K1JSY3&4ZTXUrZqIm*oN',
	MAPBOX_TOKEN:
		'pk.eyJ1IjoidGVjaG5vbG9neS1leHBlbnNlcyIsImEiOiJjbGtpYmRzZ20wOXJkM21sbnQxYXBxaDMyIn0.-uyrBdCYkaOc60FEMi_xkg',
	FEATURE_FLAG__BranchMexico: true,
	SENTRY_ERROR_SAMPLING_RATE: 0.02,
	SENTRY_TRANSACTION_SAMPLING_RATE: 0,
	SERVICES_UPDATER_SERVICE_URL: 'https://4hfdi6m2be.execute-api.us-east-1.amazonaws.com/dev',
	SERVICES_UPDATER_SERVICE_API_KEY: 's20UX9jhbE3coo9jMHvhq6TDTNLUKZ3t9LEZBjz0',
	OSRM_BASE_URL: 'https://osrm.apps-connectassistance.com',
	GOOGLE_MAPS_KEY: 'AIzaSyDfp3t0LQQRCia3xu-SYAjnj_ND1QRbyUA',
	COGNITO_CLIENT_ID: '7sical0of7pqe3hudt3lk8khhn',
	COGNITO_DOMAIN: 'https://helios-web-onboarding.auth.us-east-1.amazoncognito.com',
	ETL_DEFINITIONS_BASE_URL: 'https://gpt.stg.apps-connectassistance.com',
	ETL_DEFINITIONS_TOKEN: 'oasyMaRDueRMIleVALDROWsws',
	DLOCAL_CR_KEY: '54167752-e074-4652-8fed-c2ee876dc36b',
	DLOCAL_CO_KEY: '4a8ed97b-8ae8-476b-b662-2337857a0881',
	DLOCAL_URL: 'https://js-sandbox.dlocal.com/',
	DISPATCH_QUOTER_IFRAME: 'https://dispatch-stg.connectasistencia.com',
	EXAMPLE_FORM_IFRAME_URL: 'http://localhost:4201/iframe-example',
	TRUSTED_DOMAINS: 'localhost,connectasistencia.com,amplifyapp.com',
};
